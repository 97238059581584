<template>
  <v-card class="my-16">
    <v-card-title>
      <span> {{ indicador?.nombre || "" }} </span>
    </v-card-title>
    <div class="d-flex justify-center"> {{ indicador?.objetivo || "" }} </div>
    <v-card-text> <canvas ref="chart" /> </v-card-text>
  </v-card>
</template>

<script>
import {
  Chart,
  LinearScale,
  BarController,
  CategoryScale,
  BarElement,
Tooltip,
Legend,
} from "chart.js";

export default {
  name: "ChartContainerComponent",

  props: {
    indicador: {
      type: Object,
      default: {},
    },
  },

  methods: {
    renderChart() {
      const numerador = {
        label: this.indicador?.data[0]?.label_numerador || 'Numerador',
        data: this.indicador.data.map((data) => data.numerador),
        backgroundColor: "#404A7A",
        borderColor: "#404A7A",
        borderWidth: 1,
      };
      const denominador = {
        label: this.indicador?.data[0]?.label_denominador || 'Denominador',
        data: this.indicador.data.map((data) => data.denominador),
        backgroundColor: "#30A4F1",
        borderColor: "#30A4F1",
        borderWidth: 1,
      };

      const ctx = this.$refs.chart.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.indicador.data.map((data) => data.modalidad_contratacion),
          datasets: [numerador, denominador],
        },
        options: {
          indexAxis: "y",
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: function (context, value) {
                  var label = `${context.dataset.label}: ${context.raw}` || "";
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
        },
      });
    },
  },
  mounted() {
    Chart.register(LinearScale, BarController, CategoryScale, BarElement, Tooltip, Legend);
    this.renderChart();
  },
};
</script>

<style>
</style>