<template>
  <v-card >
    <v-card-title>Tabla de Contenidos</v-card-title>
    <v-list dense>
      <v-list-item
        v-for="(item, index) in indicadores"
        :key="index"
        @click="ScrollToSectionFtn(item?.id_indicador)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item?.nombre_indicador }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    indicadores: {
      type: Array,
      required: true,
      default: [],
    },
  },
  methods: {
    ScrollToSectionFtn(id_param) {
      const element_scp_var = document.getElementById(id_param);
      if (element_scp_var) {
        element_scp_var.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
