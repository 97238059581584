<template>
  <section>
    <h4 class="text-h4 secondary--text mt-4 mb-0">Ranking de instituciones</h4>
    <p class="mb-8 mt-4">
      Ultimo reporte generado :
      {{ moment(reporteInfo?.created_at).format("DD/MM/YYYY hh:mm A") }}
      (Tiempo de ejecución: {{ moment(reporteInfo?.duracion_segundos, "ss").format("ss") || "" }} segundos)
    </p>
    <v-row>
      <v-col cols="12" sm="6" md="2" lg="2">
        <v-select
          outlined
          label="Total de resultados"
          v-model="filters.per_page"
          :items="ctl_total_results"
          hide-details
          @change="getReporte(true)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4">
        <v-autocomplete
          outlined
          label="Institución"
          v-model="filters.id_institucion"
          :items="ctl_instituciones"
          item-text="nombre"
          item-value="id"
          hide-details
          clearable
          @change="getReporte(true)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="2">
        <v-select
          outlined
          label="Orden"
          v-model="filters.order"
          :items="ctl_order"
          hide-details
          @change="getReporte(true)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="2" lg="2">
        <v-btn
          block
          color="success"
          class="white--text py-6"
          @click="descargarReporteExcel()"
          :loading="loadingReporte"
        >
          <v-icon color="white">mdi-file-excel</v-icon> Descargar
        </v-btn>
      </v-col>
      <v-col
        v-if="haveRole('ROLE_DIRECCION_COMPRAS')"
        cols="12"
        sm="6"
        md="2"
        lg="2"
      >
        <v-btn
          v-if="!hideBTN"
          block
          color="secondary"
          class="white--text py-6"
          @click="generarReporte"
        >
          Generar reporte
        </v-btn>
      </v-col>
    </v-row>

    <section class="mt-8" v-if="!notFoundData">
      <v-card
        class="my-4"
        v-for="institucion in instituciones"
        :key="institucion.id_institucion"
      >
        <v-row align="center" class="ma-4">
          <v-col cols="3" sm="1" md="1" lg="1">
            <v-avatar color="blue-grey lighten-5" size="40">{{
              institucion.number
            }}</v-avatar>
          </v-col>
          <v-col cols="9" sm="10" md="2" lg="2">
            <span class="">{{ institucion.nombre_institucion }}</span>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="d-flex justify-space-around align-center flex-wrap">
              <div
                v-for="indicador of institucion.indicadores_b1"
                :key="indicador.id"
              >
                <div
                  class="d-flex flex-column mx-2 align-center custom-underlined"
                  :style="`--my-color: ${indicador.color}`"
                >
                  <span>{{ indicador.nombre_indicador }}</span>
                  <span>{{ indicador.puntos_resultado }}%</span>
                </div>
              </div>
            </div>

            <v-slide-y-reverse-transition>
              <div
                class="d-flex justify-space-around flex-wrap my-4"
                v-if="institucion.expand"
              >
                <div
                  v-for="indicador of institucion.indicadores_b2"
                  :key="indicador.id"
                >
                  <div
                    class="d-flex flex-column mx-2 align-center custom-underlined"
                    :style="`--my-color: ${indicador.color}`"
                  >
                    <span>{{ indicador.nombre_indicador }}</span>
                    <span>{{ indicador.puntos_resultado }}%</span>
                  </div>
                </div>
              </div>
            </v-slide-y-reverse-transition>
          </v-col>

          <v-col cols="12" sm="12" md="1" lg="1" class="text-center">
            <v-btn @click="institucion.expand = !institucion.expand" icon>
              <v-icon size="40">{{
                institucion.expand ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="6" md="1" lg="1" class="text-center">
            <v-avatar color="#309C6D" size="65"
              ><span class="white--text"
                >{{ institucion.calificacion }}%</span
              ></v-avatar
            >
          </v-col>
          <v-col cols="6" md="1" lg="1" class="text-center">
            <v-btn
              icon
              @click="
                (showGraph = true),
                  (institucionSelected = institucion),
                  getIndicadoresInstitucion(institucion.id_institucion)
              "
            >
              <v-icon>mdi-poll</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </section>

    <section class="mt-8" v-if="notFoundData">
      <h5 class="secondary--text text-center text-h5">
        No se han encontrado resultados
      </h5>
    </section>

    <v-row justify="center" class="my-8" align="center" v-if="!noMoreData">
      <v-btn
        color="secondary"
        dark
        :loading="loadMore"
        @click="getReporte(false, true)"
      >
        mostrar más
      </v-btn>
    </v-row>

    <v-dialog
      v-model="showGraph"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar dark color="primary" maxHeight="70px">
          <v-toolbar-title>Estadísticas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              (showGraph = false),
                (institucionSelected = {}),
                (indicadoresInstitucionSelected = [])
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-8 px-44">
          <container-indicadores-component
            :institucion="institucionSelected"
            :indicadores-institucion="indicadoresInstitucionSelected"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalReporte" max-width="600">
      <v-card class="pa-4">
        <v-card-text>
          <p class="text-center text-h6 secondary--text my-4">
            El reporte iniciará su ejecución en:
          </p>
          <app-countdown
            color="info--text"
            :date-now="moment()"
            :nextDay="moment().add(1, 'minutes')"
            @status="modalReporte = false"
          />
          <p class="text-center text-h6 secondary--text my-4">
            Se le notificará al correo cuando el reporte haya finalizado. Si
            después de 15 minutos no le ha llegado el correo vuelva a ejecutar
            el proceso.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dark color="secondary" @click="modalReporte = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import ContainerIndicadoresComponent from "./components/ContainerIndicadoresComponent.vue";
import AppCountdown from "@/components/AppCountdown.vue";

export default {
  name: "IndicadoresInstitucionView",
  components: {
    ContainerIndicadoresComponent,
    AppCountdown,
  },
  data: () => ({
    showGraph: false,
    institucionSelected: {},
    indicadoresInstitucionSelected: [],
    ctl_total_results: [10, 20, 50, 100],
    filters: {
      page: 1,
      per_page: 10,
      total_rows: 0,
      id_institucion: null,
      order: "DESC",
    },
    ctl_instituciones: [],
    ctl_order: ["ASC", "DESC"],
    instituciones: [],
    loadMore: false,
    notFoundData: false,
    noMoreData: false,
    counter: 0,
    loadingReporte: false,
    modalReporte: false,
    reporteInfo: {},
    hideBTN: false,
  }),
  methods: {
    async getInstituciones() {
      const response =
        await this.services.Instituciones.getCatalogoInstituciones({
          pagination: false,
        });
      if (response?.status === 200) this.ctl_instituciones = response?.data;
    },
    async getReporte(resetPagination = false, nexPage = false) {
      if (resetPagination) {
        this.filters.page = 1;
        this.instituciones = [];
        this.counter = 0;
      }
      if (nexPage) this.filters.page += 1;
      this.noMoreData = false;
      this.loadMore = true;

      const response = await this.services.Indicadores.getReporteInstituciones(
        this.filters
      );
      if (response?.status === 200) {
        if (resetPagination) this.notFoundData = response?.data?.length === 0;
        this.filters.total_rows = Number(response.headers.total_rows);

        response.data.indicadores = response.data.indicadores.map((item) => {
          item.number = this.counter + 1;
          item.indicadores_b1 = this.dividirIndicadores(item.indicadores);
          item.indicadores_b2 = this.dividirIndicadores(item.indicadores, true);
          item.expand = false;

          this.counter = item.number;
          return item;
        });

        this.instituciones = [
          ...this.instituciones,
          ...response.data.indicadores,
        ];
        this.noMoreData =
          this.filters.page * this.filters.per_page >= this.filters.total_rows;
        this.reporteInfo = response.data.reporte;
      }
      this.loadMore = false;
    },
    dividirIndicadores(indicadores, restantes = false) {
      let counter = 0;
      const divididos = [];

      if (!restantes) {
        indicadores.forEach((item) => {
          if (counter < 4) divididos.push(item);
          counter += 1;
        });
      } else {
        indicadores.forEach((item) => {
          if (counter >= 4) divididos.push(item);
          counter += 1;
        });
      }

      return divididos;
    },
    async descargarReporteExcel() {
      this.loadingReporte = true;
      const response = await this.services.Indicadores.getReporteExcel();
      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });

        const file = new File(
          [blob],
          `indicadores_instituciones_${this.moment().format(
            "DD_MM_YYYY"
          )}.xlsx`,
          {
            type: "application/octet-stream",
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
        this.loadingReporte = false;
      }
    },
    async getIndicadoresInstitucion(idInstitucion) {

      const response =
        await this.services.Indicadores.getReporteInstitucionEmpleado(
          idInstitucion
        );
      this.indicadoresInstitucionSelected = response.data;

    },
    async generarReporte() {


      const { status, data } =
        await this.services.Indicadores.generarReporteIndicadores()

      if (status == 200) {
        this.hideBTN = true;
        this.modalReporte = true;
      }


    },
  },
  async created() {
    this.getInstituciones();
    await this.getReporte();
  },
};
</script>

<style lang="scss" scoped>
.custom-underlined {
  border-bottom: 4px solid var(--my-color);
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

.px-44 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

@media screen and (max-width: 600px) {
  .px-44 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
</style>
