<template>
  <section>
    <v-row v-if="institucion?.nombre_institucion">
      <v-col cols="12" md="4" lg="3">
        <div style="position: fixed">
          <div class="d-flex flex-column text-left mb-6">
            <span>Institución</span>
            <span class="text-h6">{{ institucion?.nombre_institucion }}</span>
          </div>
          <contents-table-component :indicadores="institucion?.indicadores" />
        </div>
      </v-col>
      <v-col cols="12" md="8" lg="9">
        <template>
          <div
            v-for="indicador in indicadoresInstitucion"
            :key="indicador?.id"
            :id="indicador?.id"
          >
            <chart-container-component :indicador="indicador" />
          </div>
        </template>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ContentsTableComponent from "./ContentsTableComponent.vue";
import ChartContainerComponent from "./ChartContainerComponent.vue";

export default {
  name: "ContainerIndicadoresComponent",
  components: {
    ContentsTableComponent,
    ChartContainerComponent,
  },
  props: {
    institucion: {
      type: Object,
      required: true,
      default: {},
    },
    indicadoresInstitucion: {
      type: Array,
      required: true,
      default: [],
    },
  },
};
</script>
